import React  from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"
import BloqueDondeEstamos from "../components/bloque-donde-estamos"


const ContactoPage = () => {

	return (
	  <Layout className="page-contacto">
	    <SEO 
		    title="Formulario de contacto" 
		    description="Formulario de contacto de DMS empresa de Servicios Informáticos de Barcelona especializada en Soporte, IBM Informix y Software de Planificación de Personal"
		    />

	    <section className="bloque bloque-contacto-main">
            <h1>Contacto</h1>
            <p>Escríbenos o llama al <span>932 479 959</span> y te responderemos rápidamente</p>
	    </section>

        <BloqueContacto 
            isPage={true}
        />
        <BloqueDondeEstamos />

	  </Layout>
	);
};

export default ContactoPage
